import {Flex} from '@chakra-ui/react';

import {PageNotFound} from '../assets/icons';

export function NotFound() {
  return (
    <>
      <Flex justifyContent='center' alignItems='center' h='calc(100% - 50vh)'>
        <PageNotFound w='100%' h='50vh' />
      </Flex>
    </>
  );
}
