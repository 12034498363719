import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const AnswersIcon = createIcon({
  displayName: 'AnswersIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <path
        d='M12.7532 0.0125331H33.3332V9.26184H12.7532V0.0125331Z'
        fill='#F78061'
      />
      <path
        d='M12.7532 23.9845H33.3332V33.2351H12.7532V23.9845Z'
        fill='#F78061'
      />
      <path
        d='M12.7532 11.9978H33.3332V21.2485H12.7532V11.9978Z'
        fill='#3884FE'
      />
      <path
        d='M2 4.5L4.625 7L9 2'
        stroke='#3884FE'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 29L5.25 31L9 27'
        stroke='#3884FE'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 14L3 20M3 14L9 20'
        stroke='#F78061'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
