import React, {useRef, useState} from 'react';

import {useSelector} from 'react-redux';

import {
  Box,
  Circle,
  Flex,
  FormControl,
  IconButton,
  Input,
  Spinner,
  Text,
  UseDisclosureProps,
  VStack,
} from '@chakra-ui/react';

import {ChevronRightIcon} from '@chakra-ui/icons';
import {useQuery} from '@tanstack/react-query';
import {AnimatePresence, motion} from 'framer-motion';
import {FaNetworkWired} from 'react-icons/fa';
import {LuStretchHorizontal} from 'react-icons/lu';
import {useVirtual} from 'react-virtual';

import {getEventsOfMonthByUserId} from '../services/eventService';
import {CalendarFilter} from '../slices/calendarFilterSlice';
import {getPaginationMonthSelector} from '../slices/paginationMonthSlice';
import '../styles/windowing.css';
import {TourFilter} from '../types/tours';
import {FiltersUsers} from '../types/users';
import {sortAlpha} from '../utils/sortAlpha';
import {getAttribute} from '../utils/user-access';
import {AnimatedComponent} from './AnimatedComponent';
import {UserStack} from './UserStack';

interface FilterProps {
  data: any;
  wTab?: number;
  modal?: UseDisclosureProps;
  onChangeUser: (user: FiltersUsers, parent: string[]) => void;
  userSelected: CalendarFilter[] | TourFilter[];
  type: string;
}
interface IUserSelect {
  virtualKey: string;
  item: FiltersUsers[];
  index: number;
}

export function UserSearch(props: FilterProps) {
  const {data, modal, type, onChangeUser, userSelected} = props;
  const [parentStack, setParentStack] = useState<string[]>(
    userSelected[0] ? userSelected[0].parents! : [],
  );
  const [searchString, setSearchString] = useState<string>('');
  const [isHierarchyView, setIsHierarchyView] = useState(true);
  const parentRef = useRef<HTMLDivElement>(null);
  const dateCalendar = useSelector(getPaginationMonthSelector);
  const onDrillDown = user => {
    setParentStack([...parentStack, user.id]);
  };

  const onDrillUp = id => {
    const drillIndex = parentStack.findIndex(parentId => parentId === id);
    const slice = parentStack.slice(0, drillIndex);
    setParentStack(slice);
  };

  function validateVirtualManager() {
    if (
      data.filter(it => !Object.hasOwnProperty.call(it, 'virtualManager'))
        .length
    ) {
      filteredData = data.filter(
        it => !Object.hasOwnProperty.call(it, 'virtualManager'),
      );
    }
  }

  let filteredData;
  if (!parentStack.length) {
    if (
      data.filter(it => Object.hasOwnProperty.call(it, 'virtualManager')).length
    ) {
      validateVirtualManager();
    } else {
      if (data.filter(user => !user.managers.length).length != 0) {
        filteredData = data.filter(user => !user.managers.length);
      } else if (
        data.filter(user => user.email === getAttribute<string[]>('email', []))
      ) {
        filteredData = data.filter(
          user => user.email === getAttribute<string[]>('email', []),
        );
      } else {
        filteredData = data;
      }
    }
  } else {
    const currentParent = parentStack[parentStack.length - 1];
    filteredData = data.filter(
      user =>
        user.managers.includes(currentParent) ||
        (user.virtualManager &&
          data.find(it => it.virtualManager === currentParent)),
    );
  }

  const [[page, direction], setPage] = useState([0, 0]);

  const handleFilter = user => {
    if (modal) {
      modal?.onClose!();
    }
    onChangeUser(user, parentStack);
  };

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const variants = {
    enter: (directionEnter: number) => {
      return {
        x: directionEnter > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (directionExit: number) => {
      return {
        zIndex: 0,
        x: directionExit < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  function validationSearch(userName) {
    return (
      !searchString.length ||
      userName.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  const virtualizer = useVirtual({
    size: isHierarchyView
      ? sortAlpha(filteredData, 'name').filter(user =>
          validationSearch(user.name),
        ).length
      : sortAlpha(data, 'name').filter(user => validationSearch(user.name))
          .length,
    parentRef,
  });

  function Row(props: IUserSelect) {
    const {item, index, virtualKey} = props;
    const user = item[index];
    const handleDrillDown = () => {
      if (onDrillDown) {
        setPage([index + 1, 1]);
        onDrillDown(user);
      }
    };
    const hasChild = data.some(
      u =>
        u.managers.includes(user.id) ||
        (!u.virtualManager && data.find(it => it.virtualManager === user.id)),
    );
    const handleColor = idUser => {
      if (!userSelected[0]) {
        return '#235db0';
      }
      if (idUser === userSelected[0]['user']!['id']) {
        return '#8ac1bb';
      }
      return '#235db0';
    };
    const number = useQuery({
      queryKey: ['users-events', user.id, dateCalendar['_date']],
      queryFn: async () => {
        if (!user || type !== 'calendar') {
          return null;
        }
        return getEventsOfMonthByUserId(user.id, dateCalendar['_date']).then(
          result => {
            return result.length;
          },
        );
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

    return (
      <Flex
        key={virtualKey}
        id={user.id}
        borderRadius={15}
        transition='all 0.3s ease-in-out'
        _hover={{bg: '#8ac1bb'}}
        py={2}
        px={2}
        alignItems='center'
        w='100%'
        bg={handleColor(user.id)}
        color='white'
        justifyContent='space-between'>
        {number.isLoading ? (
          <Spinner />
        ) : (
          type === 'calendar' && (
            <Circle size='26px' bg='gray.50' color='#395b81'>
              <Text fontSize={12} fontWeight='bolder'>
                {number.data}
              </Text>
            </Circle>
          )
        )}
        <Flex
          id={user.id}
          onClick={() => handleFilter(user)}
          ml={2}
          flexDirection='column'
          minH={8}
          minW='130px'
          w='100%'
          textAlign='left'
          justifyContent='flex-start'
          alignItems='flex-start'
          cursor='pointer'>
          <Text color='white' fontSize={14}>
            {user.name || ''}
          </Text>
          <Text fontSize={10}>{user.rol || ''}</Text>
        </Flex>
        <Circle
          size='28px'
          hidden={isHierarchyView ? !hasChild : true}
          _hover={{bg: 'gray.50'}}
          cursor='pointer'
          background='white'
          onClick={handleDrillDown}>
          <ChevronRightIcon w={5} h={5} color='gray.700' />
        </Circle>
      </Flex>
    );
  }

  return (
    <Box w='100%' bg='#f1f1f1' borderRadius={20} px={4} py={3}>
      <span
        title={
          isHierarchyView ? 'Visualizar en Lista' : 'Visualizar en Jerarquía'
        }>
        <IconButton
          size='md'
          top='4px'
          right='40px'
          variant='outline'
          position='absolute'
          colorScheme='grayBlue'
          aria-label={isHierarchyView ? 'Lista' : 'Jerarquia'}
          onClick={() => {
            setIsHierarchyView(!isHierarchyView);
            if (isHierarchyView) {
              setParentStack([]);
            }
          }}
          icon={isHierarchyView ? <LuStretchHorizontal /> : <FaNetworkWired />}
        />
      </span>
      <FormControl>
        <Input
          size='sm'
          type='text'
          placeholder='Buscar usuario'
          bg='white'
          borderRadius={10}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </FormControl>
      {!!parentStack.length && (
        <Box
          mt={2}
          px={1}
          overflowX='auto'
          width='100%'
          minH={8}
          textAlign='left'>
          <UserStack
            parentStack={parentStack}
            onDrillUp={onDrillUp}
            data={data}
          />
        </Box>
      )}
      <AnimatedComponent>
        <AnimatePresence initial={false} custom={direction}>
          <Box overflow='hidden' h='100%' borderRadius={20} mt={3} w='100%'>
            <VStack w='100%' bg='white' p={3} h='auto' overflow='hidden'>
              <motion.section
                key={page}
                custom={direction}
                variants={variants}
                initial='enter'
                animate='center'
                exit='exit'
                style={{
                  overflow: 'hidden',
                  width: 'inherit',
                }}
                transition={{
                  x: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 2,
                  },
                  opacity: {
                    duration: 0.2,
                  },
                }}
                drag='x'
                dragConstraints={{left: 0, right: 0}}
                dragElastic={1}
                onDragEnd={(e, {offset, velocity}) => {
                  const swipe = swipePower(offset.x, velocity.x);
                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}>
                <Box
                  ref={parentRef}
                  style={{
                    maxHeight: '300px',
                    minHeight: '200px',
                    overflow: 'auto',
                  }}>
                  <Box
                    style={{
                      height: `${virtualizer.totalSize}px`,
                      width: '100%',
                      position: 'relative',
                    }}>
                    {virtualizer.virtualItems.map(virtualItem => {
                      return (
                        <Box
                          key={virtualItem.key}
                          ref={virtualItem.measureRef}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            paddingBottom: '10px',
                            transform: `translateY(${virtualItem.start}px)`,
                          }}>
                          <Row
                            virtualKey={virtualItem.key + ''}
                            index={virtualItem.index}
                            item={
                              isHierarchyView
                                ? sortAlpha(filteredData, 'name').filter(user =>
                                    validationSearch(user.name),
                                  )
                                : data.filter(user =>
                                    validationSearch(user.name),
                                  )
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </motion.section>
            </VStack>
          </Box>
        </AnimatePresence>
      </AnimatedComponent>
    </Box>
  );
}
