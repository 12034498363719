import React, {ReactNode} from 'react';

import {As, Button, ButtonProps} from '@chakra-ui/react';

import type {To} from 'react-router';

interface ICardProps extends ButtonProps {
  children: ReactNode;
  as?: As;
  to?: To;
}

export function Card(props: ICardProps) {
  const {children} = props;
  return (
    <Button
      mt={1}
      w='100%'
      {...props}
      bg='white'
      fontSize={16}
      padding='36px'
      cursor='pointer'
      textAlign='left'
      borderRadius={4}
      fontWeight='normal'
      _hover={{bg: 'gray.100'}}
      transition='all ease-in-out 150ms'
      boxShadow={
        '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)'
      }>
      {children}
    </Button>
  );
}
