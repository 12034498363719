import React from 'react';

import {useMediaQuery} from '@chakra-ui/react';

import {useParams} from 'react-router';

import {MobileMenu} from '../../app/MobileMenu';
import {OrganizationPickerButton} from '../OrganizationPickerButton';
import {UserSession} from '../UserSession';
import {UserSessionMobile} from '../UserSessionMobile';
import {HeaderLayout} from './HeaderLayout';
import {ScrollableLayout} from './ScrollableLayout';

export function ElementLayout({children}) {
  const [isLarge] = useMediaQuery('(min-width: 720px)');
  const params = useParams<{idOrganization?: string}>();

  return (
    <ScrollableLayout scrollY={true} px={isLarge ? '50px' : '20px'}>
      <HeaderLayout
        modeView='space-between'
        header={
          <>
            {!isLarge && <MobileMenu />}
            {params.idOrganization && (
              <OrganizationPickerButton
                idOrganization={params.idOrganization}
              />
            )}
            {!isLarge ? <UserSessionMobile /> : <UserSession />}
          </>
        }>
        {children}
      </HeaderLayout>
    </ScrollableLayout>
  );
}
