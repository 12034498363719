export function typeForm(type) {
  let name;
  switch (type) {
    case 'event-form':
      name = 'Evento';
      break;
    case 'exam-form':
      name = 'Puntuación';
      break;
    case 'user-profile-form':
      name = 'Perfil de Usuario';
      break;
    case 'bill-form':
      name = 'Viáticos';
      break;
  }
  return name;
}