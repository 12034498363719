import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {Form} from '../types/forms';

interface IFilterAnswers {
  organization: string;
  project: string;
  fromDate: Date;
  toDate: Date;
  form: Form;
}

export interface AnswersFilterState {
  data: IFilterAnswers[];
  error: string | null;
}

const initialState = {data: [], error: null} as AnswersFilterState;
const answersFilterSlice = createSlice({
  name: 'answersFilter',
  initialState,
  reducers: {
    addAnswersFilter(state, action: PayloadAction<IFilterAnswers[]>) {
      state.data = action.payload;
    },
  },
});

export const getAnswersFilterSelector = (state: RootState) =>
  state.answersFilter.data;
export const getErrorSelector = state => state.answersFilter.error;

export default answersFilterSlice.reducer;
export const {addAnswersFilter} = answersFilterSlice.actions;
