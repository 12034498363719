import {Form} from '../types/forms';
import {Project} from '../types/projects';
import {sortAlpha} from '../utils/sortAlpha';
import {typeForm} from '../utils/typeForm';
import {instance} from './index';
import {getProjectsByIds} from './projectsService';

export async function getForms() {
  return instance()
    .get<Form[]>('/forms')
    .then(response => sortAlpha(response.data, 'name'));
}

export interface FormTable extends Form {
  projectName: string;
  typeFormName: string;
}

export async function getFormsByOrganization(idOrganization: string) {
  const [formsOrg] = await Promise.all([
    instance()
      .get<Form[]>(`/forms?id_organization=${idOrganization}`)
      .then(response => sortAlpha(response.data, 'name')),
  ]);

  const projectsIds = formsOrg.map(it => it.id_project);
  const projects = await getProjectsByIds(idOrganization, projectsIds);

  const data = [] as FormTable[];
  for (const form of formsOrg) {
    const project = projects.find(
      project => project.idProject === form.id_project,
    );

    if (!project) {
      continue;
    }

    data.push(
      mapForm({
        form,
        project,
      }),
    );
  }
  return data;
}

export interface IMapForm {
  project: Project;
  form: Form;
}

function mapForm(props: IMapForm): FormTable {
  const {form, project} = props;
  return {
    ...form,
    projectName: project.name,
    typeFormName: typeForm(form.typeForm),
  };
}

export async function getFormById(idForm: string) {
  return instance()
    .get<Form>(`/forms/${idForm}`)
    .then(response => response.data);
}

export async function getFormsByProject(idProject) {
  return instance()
    .get(
      `/forms?id_project=${idProject}&typeForm=event-form&typeForm=exam-form&isEnabled=true`,
    )
    .then(response => sortAlpha(response.data, 'name'));
}

export function createForm(data) {
  return instance().post('/forms', data);
}

export function updateForm(data) {
  return instance().put(`/forms/${data.id}`, data);
}
