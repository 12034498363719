import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';

const date = new Date();

export interface PaginationMonthState {
  month: {_date: Date};
}

const initialState = {month: {_date: date}} as PaginationMonthState;
const paginationMonthSlice = createSlice({
  name: 'paginationMonth',
  initialState,
  reducers: {
    addPaginationMonth(state, action: PayloadAction<{_date: Date}>) {
      state.month = action.payload;
    },
  },
});

export const getPaginationMonthSelector = (state: RootState) =>
  state.paginationMonth.month;

export default paginationMonthSlice.reducer;
export const {addPaginationMonth} = paginationMonthSlice.actions;
