import {Project} from '../types/projects';
import {instance} from './index';

export interface IProjectResponse {
  timestamp: string;
  status: string;
  data: Project[];
}

export async function getProjectsByIds(idOrganization: string, ids: string[]) {
  const projectPromises = ids.map(idProject => {
    return instance()
      .get<IProjectResponse>(
        `directory/organizations/${idOrganization}/projects/${idProject}`,
      )
      .then(response => response.data.data);
  });
  const projects = await Promise.all(projectPromises);
  return projects.flat();
}

export async function getProjectByIdOrganization(idOrganization: string) {
  return instance()
    .get<IProjectResponse>(`directory/organizations/${idOrganization}/projects`)
    .then(response => response.data.data);
}

export function createProject(data: Project) {
  return instance().post(
    `directory/organizations/${data.organization}/projects`,
    data,
  );
}

export function updateProject(data: Project) {
  return instance().put(
    `directory/organizations/${data.organization}/projects/${data.idProject}`,
    data,
  );
}
