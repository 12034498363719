import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';
import {IInfo} from '../../types/files';

export interface InfographicState {
  data: IInfo[];
  error: string | null;
}

const initialState = {data: [], error: null} as InfographicState;
const infographicSlice = createSlice({
  name: 'infographic',
  initialState,
  reducers: {
    addInfographic(state, action: PayloadAction<IInfo>) {
      const newIds = action.payload?.idQuestion;
      const newData = [
        action.payload,
        ...state.data.filter(current => !newIds?.includes(current.idQuestion)),
      ];
      state.data = [...newData];
    },
    deleteInfographic(state, action: PayloadAction<string>) {
      const newData = state.data.filter(
        info => info.idQuestion !== action.payload,
      );
      state.data = [...newData];
    },
    cleanInfographic(state) {
      state.data = [];
    },
  },
});

export const getInfographicSelector = (state: RootState) =>
  state.infographic.data;

export default infographicSlice.reducer;
export const {addInfographic, deleteInfographic, cleanInfographic} =
  infographicSlice.actions;
