import React from 'react';

import {Provider} from 'react-redux';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter} from 'react-router-dom';

import {HappifyThemeProvider} from '../components';
import {AppRouter} from './AppRouter';
import store from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HappifyThemeProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </HappifyThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
