import React, {ReactNode} from 'react';

import {Flex} from '@chakra-ui/react';

import * as CSS from 'csstype';

interface HeaderLayoutProps {
  header: ReactNode;
  modeView: CSS.Property.JustifyContent;
  children?: ReactNode;
}

export function HeaderLayout(props: HeaderLayoutProps) {
  return (
    <>
      <Flex
        w='100%'
        h='135px'
        borderBottom='1px'
        alignItems='center'
        borderStyle='solid'
        borderBottomColor='#dedede'
        justifyContent={props.modeView}>
        {props.header}
      </Flex>
      {props.children}
    </>
  );
}
