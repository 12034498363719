import {useCallback} from 'react';

import {useToast} from '@chakra-ui/react';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import * as OrganizationsApi from '../services/organizationsService';
import {getOrganizationById} from '../services/organizationsService';
import {Organization} from '../types/organizations';

export const useOrganizations = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const query = useQuery(['organization'], OrganizationsApi.getOrganizations, {
    refetchOnWindowFocus: false,
  });
  const createMutation = useMutation(OrganizationsApi.createOrganization, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['organization']);
      toast({
        position: 'top',
        title: 'Organización Creada',
        description: 'Organización creada correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        position: 'top',
        title: 'Error',
        description: 'Error al crear organización',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const updateMutation = useMutation(OrganizationsApi.updateOrganization, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['organization']);
      toast({
        position: 'top',
        title: 'Organización Editada',
        description: 'Organización editada correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        position: 'top',
        title: 'Error',
        description: 'Error al editar organización',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const disableMutation = useMutation(OrganizationsApi.updateOrganization, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['organization']);
      toast({
        position: 'top',
        title: 'Organización desactivada',
        description: 'Organización desactivada correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        position: 'top',
        title: 'Error',
        description: 'Error organización no seleccionada',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const createOrganization = useCallback(
    (data: Organization) => {
      createMutation.mutate(data);
    },
    [createMutation],
  );

  const updateOrganization = useCallback(
    (data: Organization) => {
      updateMutation.mutate(data);
    },
    [updateMutation],
  );

  const disableOrganization = useCallback(
    (data: Organization) => {
      disableMutation.mutate(data);
    },
    [disableMutation],
  );

  return {
    ...query,
    data: query.data || [],
    createOrganization,
    updateMutation,
    updateOrganization,
    disableMutation,
    disableOrganization,
  };
};

export const useOrganizationById = (idOrganization: string | undefined) => {
  const query = useQuery({
    queryKey: ['idOrganization', idOrganization],
    queryFn: () => {
      if (!idOrganization || idOrganization === '') {
        return null;
      }
      return getOrganizationById(idOrganization);
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
  };
};
