import {useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';

import {
  Avatar,
  Center,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import {SearchIcon} from '@chakra-ui/icons';
import {MdFilterAlt, MdFilterAltOff} from 'react-icons/md';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import {useOrganizations} from '../hooks/useOrganizations';
import {addAssignKpiFilter} from '../slices/assignKpiFilterSlice';
import {addBillFilter} from '../slices/billFilterSlice';
import {addCalendarFilter} from '../slices/calendarFilterSlice';
import {addTourFilter} from '../slices/tourFilterSlice';
import {addTours} from '../slices/toursSlice';
import {Card} from './Card';

interface IOrganizationPickerListProps {
  onClose?: () => void;
}

export function OrganizationPickerList(props: IOrganizationPickerListProps) {
  const {onClose} = props;
  const organizations = useOrganizations();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [searchString, setSearchString] = useState<string>('');
  const pathSuffix =
    pathname !== '/' ? pathname.split('/').slice(2).join('/') : 'home';
  const [organizationsEnabled, setOrganizationsEnabled] = useState(true);

  const filteredOrganizations = useMemo(() => {
    return organizations.data
      .filter(it => (organizationsEnabled ? it.enabled : it))
      .filter(org =>
        org.name.toLowerCase().includes(searchString.toLowerCase()),
      );
  }, [organizations.data, organizationsEnabled, searchString]);

  const clearInputs = () => {
    dispatch(addTours([]));
    dispatch(addTourFilter([]));
    dispatch(addBillFilter([]));
    dispatch(addCalendarFilter([]));
    dispatch(addAssignKpiFilter([]));
    onClose!();
  };

  if (organizations.isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack mt={5}>
      <Stack alignItems='center' display='ruby'>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon />
          </InputLeftElement>
          <Input
            variant='outline'
            value={searchString}
            placeholder='Buscar organización'
            onChange={evt => setSearchString(evt.target.value)}
          />
        </InputGroup>
        <HStack>
          {organizationsEnabled ? (
            <span title='Organizaciones activas'>
              <IconButton
                ml={2}
                colorScheme='red'
                aria-label='enabled-org'
                icon={<MdFilterAlt />}
                onClick={() => setOrganizationsEnabled(!organizationsEnabled)}
              />
            </span>
          ) : (
            <span title='Todas las organizaciones'>
              <IconButton
                ml={2}
                variant='outline'
                colorScheme='gray'
                aria-label='all-org'
                icon={<MdFilterAltOff />}
                onClick={() => setOrganizationsEnabled(!organizationsEnabled)}
              />
            </span>
          )}
        </HStack>
      </Stack>
      <VStack maxHeight={500} w='100%' overflowY='auto' p={5}>
        {filteredOrganizations.map(org => (
          <Card
            as={RouterLink}
            onClick={clearInputs}
            justifyContent='left'
            key={org.idOrganization}
            _hover={{bg: 'gray.100'}}
            to={`/${org.idOrganization}/${pathSuffix}`}>
            <HStack justifyContent='flex-start'>
              <Avatar name={org.name} />
              <Text>{org.name}</Text>
            </HStack>
          </Card>
        ))}
      </VStack>
    </VStack>
  );
}
