import React from 'react';

import {Text, UseDisclosureProps} from '@chakra-ui/react';

import {UserSearch} from '../../components';
import {LoaderSpinner} from '../../components/LoaderSpinner';
import {CalendarFilter} from '../../slices/calendarFilterSlice';
import {TourFilter} from '../../types/tours';
import {FiltersUsers, User} from '../../types/users';

interface IUserList {
  type: string;
  modal?: UseDisclosureProps;
  users: User[];
  listUsers: FiltersUsers[];
  userSelected: CalendarFilter[] | TourFilter[];
  isLoading: boolean;
  onChangeUser: (user, parent) => void;
}

export function UserList(props: IUserList) {
  const {type, modal, userSelected, users, listUsers, isLoading, onChangeUser} =
    props;

  if (isLoading) {
    return <LoaderSpinner />;
  }
  if (users.length == 0) {
    return <Text>No hay usuarios en este proyecto</Text>;
  }
  return (
    <UserSearch
      type={type}
      data={listUsers}
      modal={modal}
      userSelected={userSelected}
      onChangeUser={(user, parent) => {
        onChangeUser(user, parent);
      }}
    />
  );
}
