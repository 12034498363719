import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../app/rootReducer';
import {FiltersUsers} from '../types/users';

export interface AssignKpiFilter {
  project?: string;
  parents?: string[];
  user: FiltersUsers | null;
}

export interface AssignKpiFilterState {
  data: AssignKpiFilter[];
  error: string | null;
}

const initialState = {data: [], error: null} as AssignKpiFilterState;
const assignKpiFilterSlice = createSlice({
  name: 'assignKpiFilter',
  initialState,
  reducers: {
    addAssignKpiFilter(state, action: PayloadAction<AssignKpiFilter[]>) {
      state.data = action.payload;
    },
  },
});

export const getAssignKpiFilterSelector = (state: RootState) =>
  state.assignKpiFilter.data;

export default assignKpiFilterSlice.reducer;
export const {addAssignKpiFilter} = assignKpiFilterSlice.actions;
