import React from 'react';

import {Grid, GridItem, useMediaQuery} from '@chakra-ui/react';

import {Outlet} from 'react-router-dom';

import SidebarMenu from '../../app/SidebarMenu';
import {ElementLayout} from './ElementLayout';

export function SidebarLayout() {
  const [isMobile] = useMediaQuery('(min-width: 720px)');
  return (
    <Grid h='100vh' templateColumns='auto 4fr' columnGap={0} rowGap={2}>
      <GridItem rowSpan={1} colSpan={1}>
        {isMobile && <SidebarMenu />}
      </GridItem>
      <GridItem colSpan={1} overflowX='auto'>
        <ElementLayout>
          <Outlet />
        </ElementLayout>
      </GridItem>
    </Grid>
  );
}
