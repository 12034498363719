import React from 'react';

import {
  Avatar,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';

import {MdLogout} from 'react-icons/md';

import CONFIG from '../config';
import {useUserInfo} from '../hooks/useUserInfo';

export function UserSessionMobile() {
  const userinfo = useUserInfo();

  return (
    <Menu>
      <MenuButton as={Box} cursor='pointer'>
        <HStack flexWrap='wrap' justifyContent='center'>
          {!userinfo.isLoading ? (
            <Avatar
              name={userinfo.data.name}
              mr='20px'
              bg='yellow.200'
              fontWeight='bold'
              color='white'
            />
          ) : (
            <Spinner size='lg' />
          )}
        </HStack>
      </MenuButton>
      <MenuList bg='white'>
        <MenuItem>{userinfo.data.name}</MenuItem>
        <MenuItem icon={<MdLogout />} as='a' href={CONFIG.AUTH_LOGOUT}>
          Cerrar Sesión
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
