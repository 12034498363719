import * as jose from 'jose';
import { JWTPayload } from 'jose';
import { getUserRoles } from '../components/ProtectedComponent';

export type IToken = JWTPayload & {
  realm_access: {
    roles: string[]
  }
  resource_access: {
    [resourceName: string]: {
      roles: string[];
    };
  };
}

export function hasAccess(accessType: string) {
  if(!localStorage.getItem('react-token')){
    return null;
  }
  const decodeJwt = jose.decodeJwt(localStorage.getItem('react-token')!) as IToken;
  return decodeJwt.realm_access.roles.includes(accessType);
}

export function hasAccessResourse(accessType: string) {
  return getUserRoles().includes(accessType);
}

export function getAttribute<T>(attribute: string, defaultValue: T) {
  const decodeJwt = jose.decodeJwt(localStorage.getItem('react-token')!) as IToken;
  return (decodeJwt[attribute] || defaultValue) as T;
}

export function ifHasAccess<T>(accessType: string, onGranted: () => T, onDenied: () => T) {
  return hasAccess(accessType) ? onGranted() : onDenied();
}

export function ifHasAccessResource<T>(accessType: string, onGranted: () => T, onDenied: () => T) {
  return hasAccessResourse(accessType) ? onGranted() : onDenied();
}