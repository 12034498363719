import {Center} from '@chakra-ui/react';

import {PageRestricted} from '../assets/icons/PageRestricted';

export function Restricted() {
  return (
    <Center>
      <PageRestricted w='100%' h='30vh' mt='30vh' />
    </Center>
  );
}
