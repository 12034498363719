import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const TipoObjetivosIcon = createIcon({
  displayName: 'TipoObjetivosIcon',
  viewBox: '0 0 34 34',
  path: (
    <>
      <path
        d='M29.529 3.86588C34.6837 9.02055 34.6837 17.3766 29.529 22.5312C24.3743 27.6859 16.0183 27.6859 10.8637 22.5312C5.70901 17.3766 5.70901 9.02055 10.8637 3.86588C16.0183 -1.28878 24.3743 -1.28878 29.529 3.86588Z'
        fill='#F75266'
      />
      <path
        d='M24.6585 8.73688C27.1225 11.2009 27.1225 15.1969 24.6585 17.6595C22.1945 20.1249 18.1985 20.1249 15.7345 17.6595C13.2705 15.1969 13.2705 11.2009 15.7345 8.73688C18.1985 6.27155 22.1945 6.27155 24.6585 8.73688Z'
        fill='#F2F2F5'
      />
      <path
        d='M20.1968 23.2682L23.3542 26.4082L26.5102 29.5495H20.1968H13.8835L17.0395 26.4082L20.1968 23.2682Z'
        fill='#5CC9BA'
      />
      <path
        d='M22.0174 33.5385H18.3747V29.1545H22.0174V33.5385Z'
        fill='#5CC9BA'
      />
      <path
        d='M10.3323 13.2331L7.1923 16.3904L4.05096 19.5464V13.2331V6.91972L7.1923 10.0757L10.3323 13.2331Z'
        fill='#5CC9BA'
      />
      <path
        d='M4.44403 15.0545H0.0599976V11.4132H4.44403V15.0545Z'
        fill='#5CC9BA'
      />
    </>
  ),
});
