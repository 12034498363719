import React, {useRef, useState} from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';

import {CerrarSesionIcon} from '../assets/icons';
import CONFIG from '../config';
import {useUserInfo} from '../hooks/useUserInfo';

export function UserSession() {
  const userinfo = useUserInfo();
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const onClose = () => setIsOpenAlert(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (!userinfo.data) {
    return <></>;
  }

  return (
    <HStack>
      <CerrarSesionIcon
        w={12}
        h={12}
        mr={2}
        cursor='pointer'
        onClick={() => setIsOpenAlert(true)}
      />
      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontWeight='bold'>
              Cerrar Sesión
            </AlertDialogHeader>
            <AlertDialogBody>¿Esta seguro de querer salir?</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                colorScheme='darkBlue'
                ref={cancelRef}
                onClick={onClose}
                variant='outline'>
                Cancelar
              </Button>
              <Button colorScheme='red' ml={3} variant='outline'>
                <a href={CONFIG.AUTH_LOGOUT}>Cerrar Sesión</a>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Avatar
        name={userinfo.data.name}
        mr='20px'
        bg='yellow.200'
        fontWeight='bold'
        color='white'
      />
      <Text fontSize='19px' fontWeight='bold'>
        {`${userinfo.data.given_name} ${userinfo.data.family_name}`}
      </Text>
    </HStack>
  );
}
