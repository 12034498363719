import * as React from 'react';

import {createIcon} from '@chakra-ui/icons';

export const FormsIcon = createIcon({
  displayName: 'FormsIcon',
  viewBox: '0 0 27 38',
  path: (
    <>
      <rect
        x='1.6875'
        y='6.80005'
        width='23.625'
        height='28.6'
        rx='2'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5588 0.801099C12.1022 0.244546 12.7493 -0.0220136 13.5 0.00142023C14.2507 -0.0220136 14.8978 0.244546 15.4412 0.801099C15.9846 1.35765 16.2563 2.02844 16.2563 2.81348V4.50071V5.0104C16.2563 5.26817 16.2871 5.47907 16.3487 5.64311C16.4104 5.80714 16.5084 5.93603 16.6429 6.02977C16.7773 6.1235 16.9062 6.18794 17.0294 6.22309C17.1527 6.25825 17.3263 6.2934 17.5504 6.32855C18.0994 6.39885 18.5616 6.64197 18.937 7.05792C19.3123 7.47387 19.5 8.12123 19.5 9H7.5C7.5 8.12123 7.68768 7.47387 8.06303 7.05792C8.43838 6.64197 8.90056 6.39885 9.44958 6.32855C9.67367 6.2934 9.84734 6.25825 9.97059 6.22309C10.0938 6.18794 10.2227 6.1235 10.3571 6.02977C10.4916 5.93603 10.5896 5.80714 10.6513 5.64311C10.7129 5.47907 10.7437 5.26817 10.7437 5.0104V4.50071V2.81348C10.7437 2.02844 11.0154 1.35765 11.5588 0.801099ZM14.1891 2.60257C13.9986 2.39167 13.7689 2.2745 13.5 2.25107C13.2311 2.2745 13.0014 2.39167 12.8109 2.60257C12.6204 2.81348 12.5252 3.07711 12.5252 3.39346C12.5252 3.68639 12.6204 3.9383 12.8109 4.1492C13.0014 4.36011 13.2311 4.47728 13.5 4.50071C13.7689 4.47728 13.9986 4.36011 14.1891 4.1492C14.3796 3.9383 14.4748 3.68639 14.4748 3.39346C14.4748 3.07711 14.3796 2.81348 14.1891 2.60257Z'
        fill='#A67700'
      />
      <path
        d='M26.5078 4.77026C26.1797 4.39111 25.793 4.20154 25.3477 4.20154H18V5.52179C17.9883 5.8874 18.0908 6.19208 18.3076 6.43581C18.5244 6.67955 18.8027 6.80142 19.1426 6.80142H23.2559C23.666 6.80142 24.0176 6.97069 24.3105 7.30921C24.6035 7.64774 24.75 8.05397 24.75 8.52791V33.6737C24.75 34.1476 24.6035 34.5538 24.3105 34.8924C24.0176 35.2309 23.666 35.4002 23.2559 35.4002H3.74414C3.33398 35.4002 2.98242 35.2309 2.68945 34.8924C2.39648 34.5538 2.25 34.1476 2.25 33.6737V8.52791C2.25 8.05397 2.39648 7.64774 2.68945 7.30921C2.98242 6.97069 3.33398 6.80142 3.74414 6.80142H7.89258C8.2207 6.80142 8.49023 6.67955 8.70117 6.43581C8.91211 6.19208 9.01172 5.8874 9 5.52179V4.20154H1.65234C1.20703 4.20154 0.820313 4.39111 0.492188 4.77026C0.164063 5.14941 0 5.59627 0 6.11083V36.0908C0 36.6053 0.164063 37.0522 0.492188 37.4313C0.820313 37.8105 1.20703 38 1.65234 38H25.3477C25.793 38 26.1797 37.8105 26.5078 37.4313C26.8359 37.0522 27 36.6053 27 36.0908V6.11083C27 5.59627 26.8359 5.14941 26.5078 4.77026Z'
        fill='#3884FE'
      />
      <path d='M8.75781 16V18.3946H4V16H6.37891H8.75781Z' fill='#73B3AB' />
      <path d='M8.75781 20.815V23.229H4V20.815H8.75781Z' fill='#73B3AB' />
      <path d='M23.0701 20.815V23.229H10.3826V20.815H23.0701Z' fill='#73B3AB' />
      <path d='M23.0701 16V18.3946H10.3826V16H23.0701Z' fill='#73B3AB' />
      <path
        d='M23.0701 25.6429V28.0569H16.6357V25.6429H23.0701Z'
        fill='#73B3AB'
      />
      <path d='M8.75781 16V18.3946H4V16H6.37891H8.75781Z' fill='#FFD25C' />
      <path d='M8.75781 11.8149V14.2289H4V11.8149H8.75781Z' fill='#73B3AB' />
      <path
        d='M23.0701 11.8149V14.2289H10.3826V11.8149H23.0701Z'
        fill='#73B3AB'
      />
      <path
        d='M23.0701 11.8149V14.2289H10.3826V11.8149H23.0701Z'
        fill='#FFD25C'
      />
      <path d='M23.0701 20.815V23.229H10.3826V20.815H23.0701Z' fill='#FFD25C' />
      <path d='M23.0701 16V18.3946H10.3826V16H23.0701Z' fill='#FFD25C' />
      <path
        d='M23.0701 25.6429V28.0569H16.6357V25.6429H23.0701Z'
        fill='#FFD25C'
      />
      <path d='M23.0701 16V18.3946H10.3826V16H23.0701Z' fill='#73B3AB' />
    </>
  ),
});
