import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RootState} from '../../app/rootReducer';
import {Form} from '../../types/forms';

export interface FormState {
  data: Form[];
  error: string | null;
  isLoading: boolean;
  startLoading: number;
  endLoading: number;
}

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  startLoading: 0,
  endLoading: 0,
} as FormState;
const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    getForm(state, action) {
      state.startLoading++;
    },
    getFormSuccess(state, action) {
      const newIds = action.payload.map(newElements => newElements.id);
      const newData = [
        ...action.payload,
        ...state.data.filter(current => !newIds.includes(current.id)),
      ];
      newData.sort((a, b) => a.id - b.id);
      state.data = newData;
      state.endLoading++;
    },
    getFormError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.endLoading++;
    },
    addForm(state, action: PayloadAction<Form>) {
      const x = '';
    },
    addFormSuccess(state, action: PayloadAction<Form>) {
      //state.data.push(action.payload);
    },
    addFormError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    updateForm(state, action: PayloadAction<Form>) {
      const x = '';
    },
    updateFormAccess(state, action: PayloadAction<Form>) {
      const index = state.data.findIndex(form => form.id === action.payload.id);
    },
    updateFormError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const getFormSelector = (state: RootState) => {
  if (state.form.startLoading === state.form.endLoading) {
    return state.form.data;
  }
  return [];
};
export const getErrorSelector = state => state.form.error;

export default formSlice.reducer;
export const {
  getForm,
  getFormSuccess,
  getFormError,
  addForm,
  addFormSuccess,
  addFormError,
  updateForm,
  updateFormAccess,
  updateFormError,
} = formSlice.actions;
