import {put, takeEvery} from 'redux-saga/effects';

import {
  addCalendarType,
  addCalendarTypeError,
  addCalendarTypeSuccess,
} from '../slices/calendarTypeSlice';

export function* getWorkers({payload}) {
  try {
    yield put(addCalendarTypeSuccess(payload));
  } catch (e: any) {
    yield put(addCalendarTypeError(e.toString()));
  }
}

export default function* rootSaga() {
  yield takeEvery(addCalendarType, getWorkers);
}
