import React from 'react';

import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import {HamburgerIcon} from '@chakra-ui/icons';

import SidebarMenu from './SidebarMenu';

export function MobileMenu() {
  const disclosure = useDisclosure();
  return (
    <>
      <HamburgerIcon
        w={12}
        h={12}
        color='darkBlue.700'
        onClick={disclosure.onOpen}
      />
      <Drawer
        placement='left'
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}>
        <DrawerOverlay />
        <DrawerContent w='100px' maxW='100px'>
          <SidebarMenu />
        </DrawerContent>
      </Drawer>
    </>
  );
}
