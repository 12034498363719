import {useCallback} from 'react';

import {useToast} from '@chakra-ui/react';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import * as ProjectsApi from '../services/projectsService';
import {Project} from '../types/projects';
import {sortAlpha} from '../utils/sortAlpha';

export function useProjectsByOrganization(idOrganization: string | undefined) {
  const query = useQuery({
    queryKey: ['project', idOrganization],
    queryFn: async () => {
      if (!idOrganization) {
        return null;
      }
      return ProjectsApi.getProjectByIdOrganization(idOrganization);
    },
    refetchOnWindowFocus: false,
  });
  return {
    ...query,
    data: sortAlpha(query.data || [], 'name') || [],
  };
}

export const useFetchProjects = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const createMutation = useMutation(ProjectsApi.createProject, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project']);
      toast({
        position: 'top',
        title: 'Proyecto Creado',
        description: 'Proyecto creado correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        position: 'top',
        title: 'Error',
        description: 'Error al crear proyecto',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const updateMutation = useMutation(ProjectsApi.updateProject, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project']);
      toast({
        position: 'top',
        title: 'Proyecto Editado',
        description: 'Proyecto editado correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        position: 'top',
        title: 'Error',
        description: 'Error al editar proyecto',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const disableMutation = useMutation(ProjectsApi.updateProject, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project']);
      toast({
        position: 'top',
        title: 'Proyecto desactivado',
        description: 'Proyecto desactivado correctamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        position: 'top',
        title: 'Error',
        description: 'Error proyecto no seleccionado',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const createProject = useCallback(
    (data: Project) => {
      createMutation.mutate(data);
    },
    [createMutation],
  );

  const updateProject = useCallback(
    (data: Project) => {
      updateMutation.mutate(data);
    },
    [updateMutation],
  );

  const disableProject = useCallback(
    (data: Project) => {
      disableMutation.mutate(data);
    },
    [disableMutation],
  );

  return {
    createProject,
    updateMutation,
    updateProject,
    disableProject,
    disableMutation,
  };
};
